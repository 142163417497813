import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import { ethGasWatchApi } from "common/api"

const initialState = {
  data: {},
  status: "initial",
}

export const fetchGasTrendAsync = createAsyncThunk(
  "gasTrend/fetchGasTrend",
  async (days = 7, { dispatch, getState }) => {
    return ethGasWatchApi.fetchGasTrend({ days })
  }
)

export const gasTrendSlice = createSlice({
  name: "gasTrend",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.data = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(fetchGasTrendAsync.pending, state => {
        state.status = "loading"
      })
      .addCase(fetchGasTrendAsync.fulfilled, (state, action) => {
        state.status = "idle"
        gasTrendSlice.caseReducers.setData(state, action)
      })
  },
})

export const { setData } = gasTrendSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGasTrend = state => state.gasTrend

export const selectGasTrendStatus = state => state.gasTrend.status

export const selectChartData = createSelector(
  selectGasTrend,
  (_, dataNaming) => dataNaming,
  (gasTrend, dataNaming) => {
    const labels = gasTrend?.data?.labels ?? []
    const dataPoints = gasTrend?.data?.[dataNaming] ?? []
    return labels.reduce(
      (prev = [], curr, index) => [...prev, { x: curr, y: dataPoints[index] }],
      []
    )
  }
)

export const selectGasTrendAverage = createSelector(
  selectGasTrend,
  (_, dataNaming) => dataNaming,
  (gasTrend, dataNaming) => {
    const dataPoints = gasTrend?.data?.[dataNaming] ?? []
    const sum = dataPoints.reduce((a, b) => a + b, 0)
    return sum / dataPoints.length || 0
  }
)

export const selectGasTrendChartData = state => [
  {
    id: "⚡ Instant",
    data: selectChartData(state, "instant"),
  },
  {
    id: "🚀 Fast",
    data: selectChartData(state, "fast"),
  },
  {
    id: "🐇 Normal",
    data: selectChartData(state, "normal"),
  },
  {
    id: "🐢 Slow",
    data: selectChartData(state, "slow"),
  },
]

export default gasTrendSlice.reducer
