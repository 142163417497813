import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import { ethGasWatchApi } from "common/api"

const initialState = {
  data: {},
  status: "initial",
}

export const fetchGasInfoAsync = createAsyncThunk(
  "gasTracker/fetchGasInfo",
  async () => {
    return ethGasWatchApi.fetchGasInfo()
  }
)

export const gasTrackerSlice = createSlice({
  name: "gasTracker",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.data = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(fetchGasInfoAsync.pending, state => {
        state.status = "loading"
      })
      .addCase(fetchGasInfoAsync.fulfilled, (state, action) => {
        state.status = "idle"
        gasTrackerSlice.caseReducers.setData(state, action)
      })
  },
})

export const { setData } = gasTrackerSlice.actions

export const selectGasInfo = state => state?.gasTracker?.data ?? {} // nullish coalescing operator
export const selectGasInfoStatus = state => state?.gasTracker?.status

export const selectGasInfoBySpeed = createSelector(
  selectGasInfo,
  (_, dataNaming) => dataNaming,
  (gasInfo, dataNaming) => gasInfo?.[dataNaming] ?? { gwei: 0, usd: 0 }
)

export default gasTrackerSlice.reducer
